<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, key, name, customFields, tags, metadata]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="languages"
  />
</template>
<script>
  import { languages } from '@/config/descriptions/languages';
  import { languagesTexts } from '@/config/pageTexts/languages.json';

  export default {
    name: 'LanguagesTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'languages/languages',
            fields: 'languages/fields',
            sortableFields: 'languages/sortableFields',
            pages: 'languages/pages',
            loading: 'languages/loading',
            resultCount: 'languages/resultCount',
            totalRecords: 'languages/totalRecords',
            originalFields: 'languages/originalFields',
            searchableAllFields: 'languages/searchableAllFields',
            success: 'languages/success',
            message: 'languages/message',
          },
          actions: {
            fields: 'languages/handleFields',
            delete: 'languages/handleDeleteLanguages',
            reset: 'languages/handleClearFilter',
            query: 'languages/handleGetLanguagesByQuery',
          },
        },
        settings: {
          describe: { ...languages },
          page: { ...languagesTexts },
        },
        route: {
          create: 'CreateLanguage',
          edit: 'EditLanguage',
          preview: 'PreviewLanguage',
        },
      };
    },
  };
</script>